// Angular Core Modules
import { Injectable } from '@angular/core';
// Environment Configs
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceUrlConstants {
  public static reqUrl: string = environment.API_URL;

  // User services
  public genrateOTP: string = ServiceUrlConstants.reqUrl + 'user/login';
  public validateOtp: string = ServiceUrlConstants.reqUrl + 'user/validateOtp';
  public resendOtp: string = ServiceUrlConstants.reqUrl + 'user/resendOtp';
  public getOtpByMobile: string = ServiceUrlConstants.reqUrl + 'user/getOTPByMobileNo';

  // added to bypass OTP
  public getOtpByMobileLogin: string = ServiceUrlConstants.reqUrl + 'user/getOTPByMobileNoLogin';
  public encryptOtp: string = ServiceUrlConstants.reqUrl + 'user/enryptOtp';

  // Order Service
  public dispatchOrderUrl: string = ServiceUrlConstants.reqUrl + 'order/listOfOrders';
  public getOrderByInvoiceNo: string = ServiceUrlConstants.reqUrl + 'order/getOrderByInvoiceNo';
  public getPendingEpodOrders: string = ServiceUrlConstants.reqUrl + 'order/getPendingEpodOrders';
  public getCompletedEpodOrders: string = ServiceUrlConstants.reqUrl + 'order/getCompletedEpodOrders';

  public requestTestCertUrl: string = ServiceUrlConstants.reqUrl + 'order/requestTestCert';


  // Epod 
  public generateOTPForEPOD: string = ServiceUrlConstants.reqUrl + 'order/generateOTPForEPOD';
  public validateOTPForEPOD: string = ServiceUrlConstants.reqUrl + 'order/validateOTPForEPOD';
  public sumitOnS3: string = ServiceUrlConstants.reqUrl + 'order/uploadDocuments';

  public getEpodDetails: string = ServiceUrlConstants.reqUrl + 'order/getEpodDetails';
  public submitEpod: string = ServiceUrlConstants.reqUrl + 'order/submitEpod';
  // feedback
  public getFeedbackOptions: string = ServiceUrlConstants.reqUrl + 'order/getFeedbackOptions';
  public submitFeedbackForOrder: string = ServiceUrlConstants.reqUrl + 'order/submitFeedbackForOrder';
  public getRatingDetails: string = ServiceUrlConstants.reqUrl + 'order/getRatingDetails';



  // mapMyIndia API
  public getITrack: string = ServiceUrlConstants.reqUrl + 'order/getITrack';

  // 
  public getMapMyIndiaResURL1: string = 'https://apis.mapmyindia.com/advancedmaps/v1/28f9jdya2q9x3qqgyoo99134smf3x6jb/route_adv/driving/';
  public getMapMyIndiaResURL2: string = '?alternatives=false&&geometries=polyline&overview=full&exclude=&steps=true&region=ind';

}