import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  minutes: any;
  seconds: any;
  timerOn: boolean = true;
  showTimer: boolean = false;
  constructor(private sharedService: SharedService, private router: Router) {
    this.sharedService.setTimer.subscribe(
      (timerValue: any) => {
        if (timerValue) {
          this.timer(timerValue);
        }
      });
  }

  ngOnInit(): void {
    const timerValue = sessionStorage.getItem('timer');
    if (timerValue) {
      this.sharedService.isOtpGenFlag.emit(false);
      this.sharedService.setShowOTPFlag(false);
      this.timer(timerValue);
    }
  }
  timer(remaining: any) {
    this.showTimer = true;
    this.minutes = Math.floor(remaining / 60);
    this.seconds = remaining % 60;

    this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
    this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
    remaining -= 1;
    if (remaining >= 0 && this.timerOn) {
      setTimeout(() => {
        this.timer(remaining);
        sessionStorage.setItem('timer', remaining);
      }, 1000);
      return;
    } else {
      this.showTimer = false;
      this.sharedService.isOtpGenFlag.emit(true);
      this.sharedService.setShowOTPFlag(true);
      sessionStorage.removeItem('timer');
    }
    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }
    // Do timeout stuff here
    // alert('Timeout for otp');
  }
  logoClikced() {
    this.router.navigate(['/dispatchOrder']);
  }
}
