import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { GeneralLayoutComponent } from './layouts/general-layout/general-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';

const routes: Routes = [{
  path: '',
  component: LoginLayoutComponent,
  loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule)
},
{
  path: 'dispatchOrder',
  component: GeneralLayoutComponent,
  canActivate:[AuthGuard],
  loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule)
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
