import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ServiceUrlConstants } from '../constants/service-url-constants';
import { DispatchDetailsObject } from '../models/orderModel';

declare var encryptor: any;

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  encryptionKey: any = "34 66 36 65 36 35 35 35 36 63 37 34 37 32 36 31 35 34 36 35 36 33 36 38 33 32 33 30 33 32 33 30";
  keys: any = '123456$#@$^@1ERF';
  skey: string = "0123456789123456";
  skey1 = "MobileDeviceotp";
  TOKEN_KEY: string = 'token';
  USER: string = 'user';
  showotpFlag: boolean = true;
  setTimer: EventEmitter<any> = new EventEmitter();
  isOtpGenFlag: EventEmitter<boolean> = new EventEmitter();
  dispatchDetailsobject = new DispatchDetailsObject;
  constructor(private http: HttpClient, private serviceUrls: ServiceUrlConstants) { }

  setShowOTPFlag(value: any) {
    this.showotpFlag = value;
  }
  getShowOTPFlag() {
    return this.showotpFlag;
  }

  setToken(value: any) {
    sessionStorage.setItem(this.TOKEN_KEY, value);
  }

  getToken() {
    return sessionStorage.getItem(this.TOKEN_KEY);
  }

  removeUser() {
    sessionStorage.removeItem(this.USER);
  }
  removeToken() {
    sessionStorage.removeItem(this.TOKEN_KEY);
  }

  // LoggedInUser
  getLoggedInUser() {
    return JSON.parse(sessionStorage.getItem(this.USER) || '{}');
  }
  setLoggedInUser(user: any) {
    sessionStorage.setItem(this.USER, JSON.stringify(user));
  }


  setDispatchDetailsobject(value: any) {
    this.dispatchDetailsobject = value;
  }
  getDispatchDetailsobject() {
    return this.dispatchDetailsobject;
  }


  getMapMyIndiaResponse(srclong: any, srclat: any, destlong: any, destLat: any) {
    return this.http.jsonp(this.serviceUrls.getMapMyIndiaResURL1 + srclong + ',' + srclat + ';' + destlong + ',' + destLat + this.serviceUrls.getMapMyIndiaResURL2, 'callback');
  }

  testFunction(value: any) {
    const secret = CryptoJS.enc.Utf8.parse(this.skey1);
    const hashDigest = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(value.toString()), secret);
    var hashInBase64 = CryptoJS.enc.Base64.stringify(hashDigest);
    return hashInBase64;

    // const secret = CryptoJS.enc.Base64.parse('AES');
    // const hashDigest = CryptoJS.SHA256(value);

    // const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
    // // hmac.update(path);
    // hmac.update(hashDigest);

    // return CryptoJS.enc.Base64.stringify(hmac.finalize());
  }




  //The set method is use for encrypt the value.
  set(value: any) {
    var key = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    var iv = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    // var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,

      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(value: any) {
    var key = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    var iv = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    // let message = CryptoJS.HmacSHA256
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  decryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.skey1);
    let _iv = CryptoJS.enc.Utf8.parse(this.skey1);

    let decrypted = CryptoJS.AES.decrypt(
      // decrypted, _key, {
      //   keySize: 16,
      //   iv: _iv,
      //   mode: CryptoJS.mode.ECB,
      //   padding: CryptoJS.pad.Pkcs7
      // }).toString(CryptoJS.enc.Utf8);
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  encryptData(data: any) {
    return encryptor.encryptData(data, this.encryptionKey);
  }

  decryptData(data: any) {
    return encryptor.decryptData(data, this.encryptionKey);
  }

}
