export class DispatchOrderObject {

}
export class OrderObject {
    mobileNo?: string;
}
export class OrderListObject {
    orderId: number;
    orderNo: string;
    orderDateTime: string
    dispatchDateTime: string;
    invoiceNo: string;
    invoiceDateTime: string
    invoiceQuantity: string;
    invoiceUOM: string;
    geofenceHitDatetime: string;
    sourceLocation: string;
    destinationLocation: string;
    productName: string;
    siteId: number;
    userMasterId: string;
    epodStatus: string;
    geoFenceHit: boolean;
    rating: string;
    shipToName: string;
    epodDoneBy: String;
    epodDateTime: string;
    isGeoFenceHit: string;

    constructor() {
        this.orderId = 0;
        this.orderNo = '';
        this.orderDateTime = '';
        this.dispatchDateTime = '';
        this.invoiceNo = '';
        this.invoiceDateTime = '';
        this.invoiceQuantity = '';
        this.invoiceUOM = '';
        this.geofenceHitDatetime = '';
        this.sourceLocation = '';
        this.destinationLocation = '';
        this.productName = '';
        this.siteId = 0;
        this.userMasterId = '';
        this.epodStatus = '';
        this.geoFenceHit = false;
        this.rating = '';
        this.shipToName = '';
        this.epodDoneBy = '';
        this.epodDateTime = '';
        this.isGeoFenceHit = '';
    }
}

export class DispatchDetailsObject {
    orderId?: string;
    orderNo?: string;
    invoiceNo?: string;
    shipToName?: string;
    createdBy?: string;
    orderQuantity?: string;
    orderUOM?: string;
    invoiceQuantity?: string;
    invoiceUOM?: string;
    orderDateTime?: Date;
    orderAckDateTime?: Date;
    vehicleNo?: string;
    sourceAllocateDateTime?: Date;
    vehicleAssignDateTime?: Date;
    totalOffloadedQuantity?: number;
    truckAssignDateTime?: Date;
    dispatchDateTime?: Date;
    geofenceHitDatetime?: Date;
    epodDateTime?: Date;
    checkInDateTime?: Date;
    checkoutDateTime?: Date;
    epodStatus?: string;
    destinationLocation?: string;
    ratings?: string;
    epodDoneBy?: string;
    contactDetails!: ContactDetails;
    productName?: string;
    contactDriver: string;
    driverDocuments:DriverDocuments;
}
export class DriverDocuments{
    acknowledgementSlipurl:string = '';
    weighingSlipUrl:string = '';
}
export class ContactDetails {
    contactNo?: number;
    contactPerson?: string;
    deliveryAddress?: string;
    specialInstructions?: string;
}
export class GenrateOTPForEPOD {
    mobileNo?: string;
    invoiceNo?: string;
    userId?: number;
}
export class ValidateOtpForEPODObject {
    mobileNo?: string;
    otp?: string;
}
export class GetEPODDetails {
    invoiceNo?: string;
}
export class EPODDetailsObject {
    orderId?: number
    orderNo?: string;
    customerName?: string;
    invoiceNo?: string;
    vehicleNo?: string;
    siteId?: number;
    userMasterId?: number;
    orderUom?: string;
    invoiceQty: number = 0;
    invoiceQuantity: number = 0;
    invoiceUOM?: string;
    suppliedQty?: number;
    invoiceDateTime?: string;
    orderDateTime?: string;
    receivedQty?: number;
    shipFromId?: string;
    shipFromName?: string;
    regMobileNo?: string;
    deliverySource?: string;
    invoiceURL?: string;
    transporterLRURL?: string;
    weighingSlipURL?: string;
    otp?: string;
    sealNo?: string;
    invoiceUom?: string;
}
export class SubmitEPODObject {
    confirmationDate: any;
    customerName: any;
    deliverySource: any;
    epodDateTime: any;
    flaggedUser: any;
    invoiceNo: any;
    invoiceDateTime: any;
    invoiceQuantity: any;
    otp: any;
    invoiceURL: any;
    orderId: any;
    orderNo: any;
    orderDateTime: any;
    orderUom: any;
    receivedQty: any;
    regMobileNo: any;
    shipFromId: any;
    shipFromName: any;
    siteId: any;
    suppliedQty: any;
    transporterLRURL: any;
    userMasterId: any;
    vehicleNo: any;
    weighingSlipURL: any;
    sealNo: any;
    sealValue: any;
    receivedQtyUOM: any;
}


export class FeedbackOptions {
    optionId?: number;
    optionValue?: string;
    isClickedFlag?: boolean = false;
}
export class SubmitFeedbackOptions {
    optionId?: number;
    optionValue?: string;
}
export class SubmitFeedbackDetails {
    dispatchSource?: string;
    feedbackComment?: string;
    feedbackOptionList?: FeedbackOptions[];
    invoiceNumber?: string;
    orderId?: number;
    rating?: number
    shipToId?: string;
    shipToName?: string;
    userId?: number;
}

export class iTrackObject {
    applicationName?: string;
    invoiceNo?: string;
}

export class RatingModel {
    orderId?: string;
    invoiceNumber?: string;
    userId?: number;
}
export class RatingDetails {
    orderId?: number;
    rating?: number;
    feedbackComment?: string;
    invoiceNumber?: string;
    userId?: number;
    feedbackOptionList: FeedbackOptions[] = []
}
