<header>
    <app-header>
    </app-header>
</header>
<div id="wrapper">
        <!-- <app-left-navigation #leftNavigation></app-left-navigation> -->
         <!-- Sidebar -->
         <div id="sidebar-wrapper">
            <ul class="sidebar-nav">
               <li class="sidebar-brand">
                  <a href="#">
                  ULTRATECH
                  CEMENT
                  </a>
               </li>
               <li>
                  <a href="#">ORDERS</a>
               </li>
            </ul>
         </div>
         <!-- /#sidebar-wrapper -->
        <main>
            <router-outlet></router-outlet>
        </main>    
</div>
<!-- <footer class="app-footer">
    <app-footer></app-footer>
</footer> -->