<div class="header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-10 col-sm-6 xs-guide1">
                <div class="logo" (click)="logoClikced()"><img src="../assets/img/brand-logo.png" alt="Logo"></div>

                <!-- <a class="btn btn-default" id="menu-toggle"><span class="navbar-toggler-icon"><i
                class="fa fa-bars" aria-hidden="true"></i></span></a> -->
            </div>
            <div class="col-md-2 col-sm-6 xs-guide2">
                <div class="time-box">
                    <!-- <a href="#">14:47 Mins</a> -->
                    <a *ngIf="showTimer">{{minutes}}:{{seconds}} Mins</a>
                </div>
            </div>

        </div>
    </div>




</div>