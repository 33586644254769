import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { GeneralLayoutComponent } from './layouts/general-layout/general-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LeftNavigationComponent } from './layouts/left-navigation/left-navigation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalModule } from 'ngx-bootstrap/modal';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Interceptor } from './core/httpInterceptor';
import { AuthGuard } from './core/auth.guard';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    GeneralLayoutComponent,
    LoginLayoutComponent,
    LeftNavigationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    MatTabsModule,
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    NgxDocViewerModule
  ],
  providers: [ DeviceDetectorService,AuthGuard
    // { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
