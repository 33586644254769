// Angular Core Modules
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class AuthGuard implements CanActivate {

    loggedInUser: any;

    constructor(
        private router: Router,
        private shareService: SharedService
    ) { }

    canActivate() {
        if (this.shareService.getLoggedInUser() && this.shareService.getToken()) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/']);
        return false;
    }
}
